@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

body{
  font-family: Roboto;
  background-color: #0E0E0E;
  color: white;
}

h1, p {
  margin-bottom: 0px;
}